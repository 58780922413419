import { createApp } from 'vue';
import { pinia } from './stores/pinia.js';
import App from './App.vue';
import { router } from './router';
import 'virtual:svg-icons-register';
import VueTablerIcons from 'vue-tabler-icons';
import { VueQueryPlugin } from '@tanstack/vue-query';
import * as Sentry from '@sentry/vue';
import 'element-plus/dist/index.css';
import './index.css';
import 'devextreme/dist/css/dx.light.css';
import { loading } from './helpers/loading';
import queryClient from './composables/queryClient';
import { ElSwitch, ElAutocomplete, ElRadioGroup, ElRadio, ElDrawer, ElDialog, ElButton, ElTable, ElPopover, ElTooltip, ElForm, ElInput, ElDatePicker, ElSelect, ElOption, ElCheckbox, ElUpload, ElIcon, ElAvatar, ElProgress, ElBadge } from 'element-plus';

const app = createApp(App);

Sentry.init({
  app,
  dsn: 'https://c115ff8dc017b466f8c20edb345ff72f@o4505883893825536.ingest.us.sentry.io/4508892022833152',
  integrations: [Sentry.browserTracingIntegration({ router }), Sentry.replayIntegration()],
  enabled: import.meta.env.VITE_VERCEL_ENV === 'production' || import.meta.env.VITE_VERCEL_ENV === 'preview',
  environment: import.meta.env.VITE_VERCEL_ENV,
  tracePropagationTargets: ['*'],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.use(ElSwitch);
app.use(ElAutocomplete);
app.use(ElRadioGroup);
app.use(ElRadio);
app.use(ElDrawer);
app.use(ElDialog);
app.use(ElButton);
app.use(ElTable);
app.use(ElPopover);
app.use(ElTooltip);
app.use(ElInput);
app.use(ElDatePicker);
app.use(ElSelect);
app.use(ElOption);
app.use(ElForm);
app.use(ElCheckbox);
app.use(ElUpload);
app.use(ElIcon);
app.use(ElAvatar);
app.use(ElProgress);
app.use(ElBadge);
app.use(VueTablerIcons);
app.directive('loading', loading);
app.use(VueQueryPlugin, {
  queryClient,
  enableDevtoolsV6Plugin: true,
});
app.use(router).use(pinia);
app.mount('#app');
