import { defineStore } from 'pinia';
import { supabase } from '../supabase.js';
import { requestErrorHandler } from '../helpers/requestErrorHandler';
import { getPublicUrl } from '@/helpers/url';
import { defaults } from 'mande';
import { jwtDecode } from 'jwt-decode';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    user: null,
    supabase,
    token: localStorage.getItem('token') || null,
  }),
  actions: {
    handleSupabaseEvents() {
      supabase.auth.onAuthStateChange((event, session) => {
        this.setUser(session?.user || null);
        this.setToken(session?.access_token || null);
      });
    },
    setToken(token) {
      defaults.headers.Authorization = `Bearer ${token}`;
      this.token = token;
      localStorage.setItem('token', token);
    },
    setUser(user) {
      this.user = user;
    },
    getUserRole() {
      const jwt = jwtDecode(this.token);
      return jwt.user_role;
    },
    async loginProvider(provider, options) {
      try {
        const redirectTo = options?.redirectTo ? `${getPublicUrl()}/redirect?next=${options.redirectTo}` : `${getPublicUrl()}/redirect`;
        const { data, error } = await supabase.auth.signInWithOAuth({
          provider,
          options: {
            queryParams: {
              hd: 'swag42.com',
            },
            redirectTo,
          },
        });
        if (error) throw error;
        return { data };
      } catch (error) {
        return requestErrorHandler(error);
      }
    },
  },
});
